<template>
  <router-view></router-view>
</template>

<script lang="tsx">

    export default {
        name: "Checkin"
    }
</script>

<style scoped>

</style>
